@import url(https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap);
:-webkit-full-screen::-webkit-scrollbar {
  height: 0.35rem;
  width: 1rem;
}

:fullscreen::-webkit-scrollbar {
  height: 0.35rem;
  width: 1rem;
}

:-webkit-full-screen::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #1e1e24;
}

:fullscreen::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #1e1e24;
}

:-webkit-full-screen::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #6649b8;
}

:fullscreen::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #6649b8;
}

body {
  margin: 0;
  font-family: 'Hind Madurai', 'Quicksand', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:-webkit-full-screen {
  overflow: scroll;
  position: fixed;
}

:-ms-fullscreen {
  overflow: scroll;
  position: fixed;
}

:fullscreen {
  overflow: scroll;
  position: fixed;
}


.App {
  text-align: center;
  position: relative;
  top: 0;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  overflow: hidden;
}

.olimpusheader {
  padding-top: 3vh;
  height: 17vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.olimpusheader img {
  height: 10vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.content {
  display: block;
  margin-top: 2vh;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}

::-webkit-backdrop{
  background-color: white;
}

::backdrop{
  background-color: white;
}

.soalimg { 
  object-fit: contain;
  width: 100%;
}

/* @media only screen and (min-width: 551px) and (max-width: 768px) {

}

@media only screen and (min-width: 381px) and (max-width: 550px) {

}

@media only screen and (max-width: 380px) {

} */


