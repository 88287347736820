@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');

:fullscreen::-webkit-scrollbar {
  height: 0.35rem;
  width: 1rem;
}

:fullscreen::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #1e1e24;
}

:fullscreen::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #6649b8;
}

body {
  margin: 0;
  font-family: 'Hind Madurai', 'Quicksand', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:fullscreen {
  overflow: scroll;
  position: fixed;
}

