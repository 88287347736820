.App {
  text-align: center;
  position: relative;
  top: 0;
  min-height: fit-content;
  overflow: hidden;
}

.olimpusheader {
  padding-top: 3vh;
  height: 17vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.olimpusheader img {
  height: 10vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.content {
  display: block;
  margin-top: 2vh;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}

::backdrop{
  background-color: white;
}

.soalimg { 
  object-fit: contain;
  width: 100%;
}

/* @media only screen and (min-width: 551px) and (max-width: 768px) {

}

@media only screen and (min-width: 381px) and (max-width: 550px) {

}

@media only screen and (max-width: 380px) {

} */

